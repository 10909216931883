import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BreadcrumbComponent implements OnInit {
    public adminHome: MenuItem;
    public adminMenu: MenuItem[];
    public title: string;

    public get isTitleVisible() {
        return this.router.url.includes('dashboard') || this.router.url.includes('maintenance');
    }

    public constructor(private router: Router, private route: ActivatedRoute, private zone: NgZone, private cd: ChangeDetectorRef) {
        this.adminMenu = [];
    }

    public async ngOnInit() {
        this.setAdminMenu();
    }

    private setAdminMenu() {
        this.adminHome = {
            icon: 'pi pi-home',
            routerLink: '/'
        };
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router)
            )
            .subscribe((event) => {
                // set breadcrumbs
                this.adminMenu = this.getBreadcrumbs(this.route.root);
                this.adminMenu = [...this.adminMenu];
                this.cd.detectChanges();
            });
    }

    private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
        const ROUTE_DATA_BREADCRUMB = 'title';
        // get the child routes
        const children: ActivatedRoute[] = route.children;
        // console.log('route', route);
        // return if there are no more children
        if (0 === children.length) {
            return breadcrumbs;
        }

        // iterate over each children
        children.forEach((child) => {
            // verify the custom data property "breadcrumb" is specified on the route
            if (child.outlet !== PRIMARY_OUTLET || !child.snapshot.data[ROUTE_DATA_BREADCRUMB] || 0 >= child.snapshot.url.length) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }

            // get the route's URL segment
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

            // append route URL to URL
            url += `/${routeURL}`;

            // add breadcrumb
            const breadcrumb: MenuItem = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                routerLink: url
            };
            breadcrumbs.push(breadcrumb);
            this.title = breadcrumb.label;

            // recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        });
        return breadcrumbs;
    }
}
