import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AccessToken, OktaAuth } from '@okta/okta-auth-js';
import { environment } from '@environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    public constructor(private cookieService: CookieService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // okta
        if (request.url.includes('LoginAdminRequest')) {
            const token = this.oktaAuth.getAccessToken();
            return next.handle(
                request.clone({
                    headers: request.headers.set('authorization', `Bearer ${token}`)
                })
            );
        }

        if (this.cookieService.check('token') && !request.url.includes(environment.r2Host)) {
            return next.handle(
                request.clone({
                    headers: request.headers.set('x-authorization', this.cookieService.get('token'))
                })
            );
        }

        return next.handle(request);
    }
}
