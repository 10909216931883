import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { UserStateService } from '../states/user-state.service';

@Injectable({
    providedIn: 'root'
})
export class AccessGuard implements CanActivate {
    public constructor(
        private router: Router,
        private cookieService: CookieService,
        private userStateService: UserStateService
    ) {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.cookieService.check('token')) {
            this.userStateService.signOut();
            this.router.navigateByUrl('/login');
            return false;
        }

        this.userStateService.isSignedIn.next(true);

        return true;
    }
}
