import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserStateService } from '@core/states/user-state.service';
import { ServerTimeService } from '@core/services/server-time.service';
import { TimerService } from '@core/services/timer.service';
import dayjs from 'dayjs';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit {
    public environment = environment;
    private unsubscribe = new Subject<void>();
    public title = 'back-office';
    public isVisibleSideMenu: boolean;

    public get isSignedIn() {
        return this.userStateService.isSignedIn;
    }

    public constructor(private userStateService: UserStateService, private serverTimeService: ServerTimeService, private timer: TimerService) {}

    public ngOnInit() {
        this.timer.start();
        this.timer.get().pipe(takeUntil(this.unsubscribe)).subscribe(this.tick);
    }

    public onToggleSideMenu(isToggle: boolean) {
        this.isVisibleSideMenu = isToggle;
    }

    private tick = async () => {
        if (!this.serverTimeService.current) {
            return;
        }

        this.serverTimeService.current = dayjs.utc(this.serverTimeService.current).add(1, 'second');
    };
}
