import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { environment } from '@environments/environment';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from '@shared/components/layout/header/header.component';
import { FooterComponent } from '@shared/components/layout/footer/footer.component';
import { NavbarComponent } from '@shared/components/layout/navbar/navbar.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

declare let window: Window;

const oktaAuth = new OktaAuth({
    issuer: environment.oktaIssuer,
    clientId: environment.oktaClientId,
    redirectUri: `${window.location.origin}/login/callback`
});

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent, NavbarComponent, BreadcrumbComponent],
    imports: [CoreModule, SharedModule, BrowserModule, BrowserAnimationsModule, AppRoutingModule, OktaAuthModule.forRoot({ oktaAuth })],
    exports: [BreadcrumbComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}
