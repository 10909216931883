<ul class="nav" [class.prod]="environment.production">
    <li *ngFor="let menu of menuList">
        <a [routerLink]="menu.path" [class.active]="menu.active" [class.sub]="menu.isSubmenu">{{ menu.display }}</a>
        <ul class="sub-nav" *ngIf="menu.isSubmenu">
            <li *ngFor="let subMenu of menu.subMenu">
                <a [routerLink]="subMenu.path"
                   [routerLinkActive]="!!subMenu.path ? 'active' : null">{{ subMenu.display }}</a>
            </li>
        </ul>
    </li>
</ul>

