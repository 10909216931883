import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '@core/guards/access.guard';
import { LoginPageGuard } from '@core/guards/login-page.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [LoginPageGuard],
        data: {
            title: 'Login'
        }
    },
    {
        path: 'brand',
        loadChildren: () => import('./pages/brand/brand.module').then((m) => m.BrandModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Brand Management'
        }
    },
    {
        path: 'series',
        loadChildren: () => import('./pages/series/series.module').then((m) => m.SeriesModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Series Management'
        }
    },
    {
        path: 'tournament',
        loadChildren: () => import('./pages/tournament/tournament.module').then((m) => m.TournamentModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Tournament Management'
        }
    },
    {
        path: 'venue',
        loadChildren: () => import('./pages/venue/venue.module').then((m) => m.VenueModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Venue Management'
        }
    },
    {
        path: 'staking',
        loadChildren: () => import('./pages/staking/staking.module').then((m) => m.StakingModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Staking Management'
        }
    },
    {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then((m) => m.PaymentModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Payment Management'
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Admin Management'
        }
    },
    {
        path: 'seller',
        loadChildren: () => import('./pages/seller/seller.module').then((m) => m.SellerModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Seller Management'
        }
    },
    {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
        canActivate: [AccessGuard],
        data: {
            title: 'User Management'
        }
    },
    {
        path: 'log-tracking',
        loadChildren: () => import('./pages/log-tracking/log-tracking.module').then((m) => m.LogTrackingModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Log Tracking'
        }
    },
    {
        path: 'report',
        loadChildren: () => import('./pages/report/report.module').then((m) => m.ReportModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Report'
        }
    },
    {
        path: 'psp',
        loadChildren: () => import('./pages/psp/psp.module').then((m) => m.PSPModule),
        canActivate: [AccessGuard],
        data: {
            title: 'PSP'
        }
    },
    {
        path: 'promotion',
        loadChildren: () => import('./pages/promotion/promotion.module').then((m) => m.PromotionModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Promotion'
        }
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./pages/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Maintenance'
        }
    },
    {
        path: 'banners',
        loadChildren: () => import('./pages/banners/banners.module').then((m) => m.BannersModule),
        canActivate: [AccessGuard],
        data: {
            title: 'Banners'
        }
    },
    {
        path: '**',
        redirectTo: 'report'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
