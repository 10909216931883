import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@models';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public constructor(private http: HttpClient) {}

    public signIn() {
        return this.http.post<DTO.BackOffice.Admin.LoginAdminResponse>('/Admin/LoginAdminRequest', null).toPromise();
    }

    public signOut() {
        return this.http.post<void>('/Admin/LogoutAdminRequest', {}).toPromise();
    }

    public getPokercraftUrl(data: DTO.BackOffice.Member.GetPokerCraftUrlRequest) {
        return this.http.get(`/Pokercraft/Url?sellerId=${data.sellerId}&memberId=${data.memberId}`, { responseType: 'text' }).toPromise();
    }
}
