import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';

import { EnsureModuleLoadedOnceGuard } from './module-import-guard';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { HttpHostInterceptor } from './interceptors/http-host.interceptor';

const COMMON_MODULES = [
    HttpClientModule
];

@NgModule({
    imports: [
        CommonModule,
        ...COMMON_MODULES
    ],
    exports: [
        ...COMMON_MODULES
    ],
    providers: [
        DialogService,
        MessageService,
        ConfirmationService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHostInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true
        }
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
}
