import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { DTO } from '@models';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environments/environment';
import { UserStateService } from '@core/states/user-state.service';
import { UserService } from '@core/services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [DialogService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    @Output() public toggleSideMenu = new EventEmitter<boolean>();
    public userMenuItems: MenuItem[];
    public environment = environment;
    public userInfo: DTO.BackOffice.Admin.GetAdminInfoResponse;
    public isSideMenuPanelToggle: boolean;

    public get isSignedIn() {
        return this.userState.isSignedIn;
    }

    public constructor(private router: Router, private cdRef: ChangeDetectorRef, private authService: AuthService, private userService: UserService, private userState: UserStateService) {}

    public ngOnInit() {
        this.userMenuItems = [
            {
                items: [
                    {
                        label: 'Logout',
                        icon: 'pi pi-sign-out',
                        command: async () => {
                            await this.signOut();
                        }
                    }
                ]
            }
        ];

        this.userState.isSignedIn.subscribe(this.toggleSignIn);
    }

    private async signOut() {
        await this.authService.signOut();
        this.userState.signOut();
        this.cdRef.markForCheck();
        await this.router.navigate(['login']);
    }

    private toggleSignIn = async (toggle: boolean) => {
        if (!toggle) {
            this.userInfo = null;
            this.userState.info.next(null);
            this.cdRef.markForCheck();
            return;
        }

        if (this.userInfo) {
            this.userState.info.next(this.userInfo);
            return;
        }

        try {
            const res = await this.userService.getUserInfo();
            this.userInfo = res;
            this.userState.info.next(res);
        } catch {
            this.userState.signOut();
            await this.router.navigate(['login']);
        } finally {
            this.cdRef.markForCheck();
        }
    };

    public onToggleSideMenu() {
        this.isSideMenuPanelToggle = !this.isSideMenuPanelToggle;
        this.toggleSideMenu.emit(this.isSideMenuPanelToggle);
    }
}
