import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DtPipe } from './dt.pipe';
import { MoneyPipe } from './money.pipe';
import { LocalDtPipe } from './local-dt.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';

@NgModule({
    declarations: [
        DtPipe,
        MoneyPipe,
        LocalDtPipe,
        CurrencySymbolPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DtPipe,
        MoneyPipe,
        LocalDtPipe,
        CurrencySymbolPipe
    ]
})
export class PipeModule { }
