import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@models';
import { map, tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ServerTimeService } from './server-time.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public constructor(private http: HttpClient, private serverTimeService: ServerTimeService) {}

    public getUserInfo() {
        return this.http
            .post<DTO.BackOffice.Admin.GetAdminInfoResponse>('/Admin/GetAdminInfoRequest', {}, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }
}
