import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { VM } from '@models';

@Injectable({
    providedIn: 'root'
})
export class ListStateService {
    public brand = new BehaviorSubject<VM.BrandListState>(null);
    public series = new BehaviorSubject<VM.SeriesListState>(null);
    public tournament = new BehaviorSubject<VM.TournamentListState>(null);
    public venue = new BehaviorSubject<VM.VenueListState>(null);
    public staking = new BehaviorSubject<VM.StakingListState>(null);
    public user = new BehaviorSubject<VM.UserListState>(null);
    public seller = new BehaviorSubject<VM.SellerListState>(null);
    public pendingWithdrawal = new BehaviorSubject<VM.PaymentListState>(null);
    public paymentHistory = new BehaviorSubject<VM.PaymentListState>(null);
    public reconciliationList = new BehaviorSubject<VM.PaymentListState>(null);
    public refundHistory = new BehaviorSubject<VM.PaymentListState>(null);
    public userLog = new BehaviorSubject<VM.LogTrackingListState>(null);
    public adminLog = new BehaviorSubject<VM.LogTrackingListState>(null);
    public adminLogin = new BehaviorSubject<VM.LogTrackingListState>(null);
    public userLogin = new BehaviorSubject<VM.LogTrackingListState>(null);
    public twoFactorHistory = new BehaviorSubject<VM.LogTrackingListState>(null);
    public paymentReport = new BehaviorSubject<VM.ReportListState>(null);
    public stakingReport = new BehaviorSubject<VM.ReportListState>(null);
    public userStakingReport = new BehaviorSubject<VM.ReportListState>(null);
    public promotion = new BehaviorSubject<VM.PromotionListState>(null);

    private readonly seriesSessionStorageKey = 'psixAdminSeriesState';
    private readonly tournamentSessionStorageKey = 'psixAdminTournamentState';
    private readonly stakingSessionStorageKey = 'psixAdminStakingState';
    private readonly userSessionStorageKey = 'psixAdminUserState';
    private readonly sellerSessionStorageKey = 'psixAdminSellerState';
    private readonly pendingWithdrawalSessionStorageKey = 'psixAdminPendingWithdrawalState';
    private readonly paymentHistorySessionStorageKey = 'psixAdminPaymentHistoryState';
    private readonly reconciliationListSessionStorageKey = 'psixAdminReconciliationListState';
    private readonly refundHistorySessionStorageKey = 'psixAdminRefundHistoryState';
    private readonly userLogSessionStorageKey = 'psixAdminUserLogState';
    private readonly adminLogSessionStorageKey = 'psixAdminAdminLogState';
    private readonly adminLoginSessionStorageKey = 'psixAdminAdminLoginState';
    private readonly userLoginSessionStorageKey = 'psixAdminUserLoginState';
    private readonly twoFactorHistorySessionStorageKey = 'psixAdminTwoFactorHistoryState';
    private readonly paymentReportSessionStorageKey = 'psixAdminPaymentReportState';
    private readonly stakingReportSessionStorageKey = 'psixAdminStakingReportState';
    private readonly userStakingReportSessionStorageKey = 'psixAdminUserStakingReportState';
    private readonly promotionSessionStorageKey = 'psixAdminPromotionState';

    public constructor(@Inject(DOCUMENT) private document: Document) {
        this.init();
    }

    private init() {
        this.initState<VM.SeriesListState>(this.series, this.seriesSessionStorageKey);
        this.initState<VM.TournamentListState>(this.tournament, this.tournamentSessionStorageKey);
        this.initState<VM.StakingListState>(this.staking, this.stakingSessionStorageKey);
        this.initState<VM.UserListState>(this.user, this.userSessionStorageKey);
        this.initState<VM.SellerListState>(this.seller, this.sellerSessionStorageKey);
        this.initState<VM.PaymentListState>(this.pendingWithdrawal, this.pendingWithdrawalSessionStorageKey);
        this.initState<VM.PaymentListState>(this.paymentHistory, this.paymentHistorySessionStorageKey);
        this.initState<VM.PaymentListState>(this.reconciliationList, this.reconciliationListSessionStorageKey);
        this.initState<VM.PaymentListState>(this.refundHistory, this.refundHistorySessionStorageKey);
        this.initState<VM.LogTrackingListState>(this.userLog, this.userLogSessionStorageKey);
        this.initState<VM.LogTrackingListState>(this.adminLog, this.adminLogSessionStorageKey);
        this.initState<VM.LogTrackingListState>(this.adminLogin, this.adminLoginSessionStorageKey);
        this.initState<VM.LogTrackingListState>(this.userLogin, this.userLoginSessionStorageKey);
        this.initState<VM.LogTrackingListState>(this.twoFactorHistory, this.twoFactorHistorySessionStorageKey);
        this.initState<VM.ReportListState>(this.paymentReport, this.paymentReportSessionStorageKey);
        this.initState<VM.ReportListState>(this.stakingReport, this.stakingReportSessionStorageKey);
        this.initState<VM.ReportListState>(this.userStakingReport, this.userStakingReportSessionStorageKey);
        this.initState<VM.PromotionListState>(this.promotion, this.promotionSessionStorageKey);
    }

    public empty() {
        this.brand.next(null);
        this.series.next(null);
        this.tournament.next(null);
        this.staking.next(null);
        this.user.next(null);
        this.seller.next(null);
        this.venue.next(null);
        this.pendingWithdrawal.next(null);
        this.paymentHistory.next(null);
        this.reconciliationList.next(null);
        this.refundHistory.next(null);
        this.userLog.next(null);
        this.adminLog.next(null);
        this.adminLogin.next(null);
        this.userLogin.next(null);
        this.twoFactorHistory.next(null);
        this.paymentReport.next(null);
        this.stakingReport.next(null);
        this.userStakingReport.next(null);
        this.promotion.next(null);
    }

    private initState<T>(subject: BehaviorSubject<T>, sessionStorageKey: string) {
        const sessionState = this.document.defaultView.sessionStorage.getItem(sessionStorageKey);
        const parsed = JSON.parse(sessionState) as T;

        if (parsed) {
            if (Object.prototype.hasOwnProperty.call(parsed, 'requestDates') && parsed['requestDates']) {
                parsed['requestDates'][0] = new Date(parsed['requestDates'][0]);
                parsed['requestDates'][1] = new Date(parsed['requestDates'][1]);
            }
            if (Object.prototype.hasOwnProperty.call(parsed, 'updateDates') && parsed['updateDates']) {
                parsed['updateDates'][0] = new Date(parsed['updateDates'][0]);
                parsed['updateDates'][1] = new Date(parsed['updateDates'][1]);
            }
            subject.next(parsed);
        }

        subject.subscribe((data) => {
            if (!data) {
                this.document.defaultView.sessionStorage.removeItem(sessionStorageKey);
                return;
            }
            this.document.defaultView.sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
        });
    }
}
