import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { DTO } from '@models';
import { CookieService } from 'ngx-cookie-service';
import { ListStateService } from './list-state.service';

@Injectable({
    providedIn: 'root'
})
export class UserStateService {
    public constructor(private cookieService: CookieService, private listState: ListStateService) {}

    public isSignedIn = new BehaviorSubject<boolean>(false);
    public info = new BehaviorSubject<DTO.BackOffice.Admin.GetAdminInfoResponse>(null);

    public signOut() {
        this.isSignedIn.next(false);
        this.info.next(null);

        this.listState.empty();
        this.cookieService.delete('token', '/');
    }
}
