<h1 class="heading-title">
    <span class="logo"><img src="assets/img/pokerstake-logo.svg" alt="PokerStake"></span>
    <em class="admin-title">Admin <strong *ngIf="!environment.production">({{ environment.env }})</strong></em>
</h1>

<div class="header-admin" *ngIf="(isSignedIn | async) && userInfo">
    <span class="admin-name" (click)="adminMenu.toggle($event)">{{ userInfo?.adminName }} <i class="pi pi-angle-down"></i></span>
    <p-menu class="admin-menu" #adminMenu [popup]="true" [model]="userMenuItems" [style]="{top: '62px'}"></p-menu>
    <button type="button" class="button-menu" (click)="onToggleSideMenu()"><i class="pi" [ngClass]="isSideMenuPanelToggle ? 'pi-times' : 'pi-bars' "></i></button>
</div>
