import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { VM } from '@models';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
    public environment = environment;
    public menuList: Array<VM.SideMenu>;
    private readonly menus = [
        { id: 'brand', label: 'Brand' },
        { id: 'series', label: 'Series' },
        { id: 'tournament', label: 'Tournament' },
        { id: 'venue', label: 'Venue' },
        { id: 'staking', label: 'Staking' },
        {
            id: 'payment',
            label: 'Payment',
            subMenu: [
                { id: 'psp-balance', label: 'PSP Balance' },
                { id: 'withdrawal-list', label: 'Pending Withdrawal' },
                { id: 'payment-history-list', label: 'Payment History' },
                { id: 'refund-history-list', label: 'Refund History' },
                { id: 'reconciliation-list', label: 'Reconciliation' }
            ]
        },
        { id: 'admin', label: 'Admin' },
        { id: 'user', label: 'User' },
        { id: 'seller', label: 'Seller' },
        { id: 'psp', label: 'PSP' },
        {
            id: 'log-tracking',
            label: 'Log Tracking',
            subMenu: [
                { id: 'admin-log', label: 'Admin Log' },
                { id: 'user-log', label: 'User Log' },
                { id: 'admin-login', label: 'Admin Login' },
                { id: 'user-login', label: 'User Login' },
                { id: '2fa-history', label: '2FA History' }
            ]
        },
        {
            id: 'report',
            label: 'Report',
            subMenu: [
                { id: 'dashboard', label: 'Dashboard' },
                { id: 'payment', label: 'Payment Report' },
                { id: 'staking', label: 'Staking Report' },
                { id: 'user-staking', label: 'User Staking Report' }
            ]
        },
        // { id: 'promotion', label: 'PokerGo Promotion' },
        { id: 'maintenance', label: 'Maintenance' },
        { id: 'banners', label: 'Banners' }
    ];

    public constructor(private router: Router, private cdRef: ChangeDetectorRef) {}

    public ngOnInit() {
        this.initMenu();
        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                startWith(this.router)
            )
            .subscribe(this.setCurrentMenu);
    }

    private initMenu() {
        this.menuList = this.menus.map<VM.SideMenu>((menu) => ({
            id: menu.id,
            display: menu.label,
            path: `/${menu.id}`,
            active: false,
            isSubmenu: !!menu.subMenu,
            subMenu: (menu.subMenu || []).map<VM.SideMenu>((sub) => ({
                id: sub.id,
                display: sub.label,
                path: `/${menu.id}/${sub.id}`,
                active: false
            }))
        }));
    }

    private setCurrentMenu = (event) => {
        const uri = event.url.slice(1).split('/');
        this.menuList.forEach((menu) => {
            if (menu.isSubmenu && uri[1]) {
                menu.active = uri[0].includes(menu.id);
                menu.subMenu.forEach((sub) => {
                    sub.active = uri[1].includes(sub.id);
                });
            } else {
                menu.active = uri[0].includes(menu.id);
            }
        });

        this.cdRef.markForCheck();
    };
}
