<app-header class="header" [class.prod]="environment.production" (toggleSideMenu)="onToggleSideMenu($event)"></app-header>
<article class="contents" *ngIf="isSignedIn | async; else loginContainer">
    <app-navbar class="navbar" [class.panel-open]="isVisibleSideMenu"></app-navbar>
    <section class="content-container">
        <app-breadcrumb class="breadcrumb"></app-breadcrumb>
        <section class="content">
            <router-outlet></router-outlet>
        </section>
    </section>
    <app-footer class="footer"></app-footer>
</article>

<ng-template #loginContainer>
    <article class="login-container">
        <router-outlet></router-outlet>
    </article>
</ng-template>

<p-toast [style]="{ opacity: 1 }" [baseZIndex]="2000"></p-toast>
<p-confirmDialog></p-confirmDialog>

