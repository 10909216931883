import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { DTO } from '@models';
import { environment } from '@environments/environment';
import { MessageService } from 'primeng/api';
import { UserStateService } from '../states/user-state.service';

@Injectable()
export class HttpHostInterceptor<T, Z> implements HttpInterceptor {
    public constructor(private router: Router, private userState: UserStateService, private messageService: MessageService) {}

    public intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<Z>> {
        let endPoint = environment.backendHost;
        if (request.url.includes('//')) {
            endPoint = '';
        }

        return next
            .handle(
                request.clone({
                    url: `${endPoint}${request.url}`
                })
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let message = `Error Status: ${error.status}\nMessage: `;
                    const signOutErrorCodes = [DTO.Enums.Common.ErrorCodes.SESSION_EXPIRED, DTO.Enums.Common.ErrorCodes.INVALID_SESSION, DTO.Enums.Common.ErrorCodes.ACCESS_DENIED, DTO.Enums.Common.ErrorCodes.INVALID_ADMIN];
                    if (Object.hasOwnProperty.call(error.error, 'errorCode') && signOutErrorCodes.includes(error.error.errorCode)) {
                        if (signOutErrorCodes.includes(DTO.Enums.Common.ErrorCodes.SESSION_EXPIRED)) {
                            this.messageService.add({ severity: 'error', summary: 'Session expired', detail: 'Your session has expired. Please login again.' });
                        }
                        message += error.error.description;
                        this.userState.signOut();
                        this.router.navigateByUrl('/login');
                    } else {
                        message += error.message;
                    }

                    console.error('Error message', error, message);
                    return throwError(error);
                })
            );
    }
}
